import {
	Box,
	Card,
	Checkbox,
	Divider,
	FormControl,
	InputAdornment,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import config from "../../config";
import { Clear, Send, Upload } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import profileApi from "../../api/profile";
import { showError, showSuccess } from "../../App";
import campaignApi from "../../api/campaign";
import emailApi from "../../api/email";
import Papa from "papaparse";
import categoryApi from "../../api/categories";

export default function Email() {
	const [loading, setLoading] = useState(false);
	const [campaigns, setCampaigns] = useState([]);
	const [mode, setMode] = useState("category");
	const [data, setData] = useState({
		campaign_id: "",
		emails: [],
		category_id: "",
	});
	const [categories, setCategories] = useState([]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		const text = await file.text();
		const users = [];

		Papa.parse(text, {
			header: true,
			skipEmptyLines: true,
			transform: (value) => (value === "" ? null : value), // Transform empty strings to null
			complete: (results) => {
				users.push(...results.data);
			},
			error: (error) => {
				showError("Error parsing CSV: " + error.message);
				setLoading(false);
			},
		});

		console.log(users);

		const res = await emailApi.sendCampaignEmail({
			campaign_id: data.campaign_id,
			// users: users,
			emails: data.emails,
		});
		if (res.success) {
			// setData({
			// 	title: "",
			// 	body: "",
			// 	user_ids: [],
			// });
			showSuccess("Email sent successfully");
		}
		setLoading(false);
	};
	const fetchCampaigns = async () => {
		const response = await campaignApi.getAllCampaigns();
		if (response.success) {
			setCampaigns(response.data);
		}
	};

	const fetchCategories = async () => {
		const response = await categoryApi.getAllCategories();
		if (response.success) {
			setCategories(response.data);
		}
	};

	const [selectAll, setSelectAll] = useState(false);
	const [users, setUsers] = useState([]);
	const [filePath, setFilePath] = useState("");
	const [file, setFile] = useState("");

	const fetchUsers = async () => {
		const res = await profileApi.getAllProfiles();
		if (res.success) {
			setUsers(res.data);
		}
	};

	useEffect(() => {
		fetchCampaigns();
		fetchUsers();
		fetchCategories();
	}, []);

	const uploadFile = (e) => {
		setFile(e.target.files[0]);
		setFilePath(e.target.value);
	};

	const handleReset = () => {
		setFilePath("");
		setFile("");
	};

	return (
		<Box
			className="p-5 mx-auto flex items-center flex-col gap-10 justify-center w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<Card className="w-full p-5 flex flex-col gap-3 md:w-[30rem] mx-auto">
				<Typography variant="h5">Send Email</Typography>
				<Divider />
				<form
					onSubmit={async (e) => {
						e.preventDefault();
						const res =
							mode === "manual"
								? await emailApi.sendCampaignEmail({
										campaign_id: data.campaign_id,
										emails: data.emails,
								  })
								: mode === "csv"
								? await emailApi.sendCampaignEmailFromCsv({
										campaign_id: data.campaign_id,
										users: users,
								  })
								: await emailApi.sendCampaignEmailByCategory({
										campaign_id: data.campaign_id,
										category_id: data.category_id,
								  });
						if (res.success) {
							showSuccess("Email sent successfully");
						} else {
							showError("Failed to send email");
						}
					}}
					className="flex flex-col gap-5"
				>
					<FormControl
						fullWidth
						className="input-field"
						variant="outlined"
						required
						disabled={loading}
					>
						<InputLabel
							htmlFor="outlined-ado	rnment"
							className="input-label"
						>
							Campaign
						</InputLabel>

						<Select
							className="w-full"
							value={data.campaign_id}
							onChange={(e) => {
								setData({
									...data,
									campaign_id: e.target.value,
								});
							}}
							input={<OutlinedInput label={"Campaign"} />}
						>
							{campaigns.map((campaign) => (
								<MenuItem key={campaign.id} value={campaign.id}>
									<ListItemText primary={campaign.title} />
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl
						fullWidth
						className="input-field"
						variant="outlined"
						required
						disabled={loading}
					>
						<InputLabel
							htmlFor="outlined-adornment"
							className="input-label"
						>
							Mode
						</InputLabel>
						<Select
							className="w-full"
							value={mode}
							onChange={(e) => {
								setMode(e.target.value);
							}}
							input={<OutlinedInput label={"Mode"} />}
						>
							<MenuItem value="manual">Manual</MenuItem>
							<MenuItem value="csv">CSV</MenuItem>
							<MenuItem value="category">Category</MenuItem>
						</Select>
					</FormControl>

					{mode === "csv" && (
						<TextField
							type="file"
							onChange={uploadFile}
							value={filePath}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{filePath && !loading && (
											<Clear
												className="cursor-pointer"
												onClick={handleReset}
											/>
										)}
									</InputAdornment>
								),
							}}
							disabled={loading}
							required
						/>
					)}

					{mode === "manual" && (
						<FormControl
							fullWidth
							className="input-field"
							variant="outlined"
							required
							disabled={loading}
						>
							<InputLabel
								htmlFor="outlined-adornment"
								className="input-label"
							>
								Selected Users
							</InputLabel>
							<Select
								multiple
								className="w-full"
								value={data.emails}
								onChange={(e) => {
									setData({
										...data,
										emails: e.target.value,
									});
								}}
								input={
									<OutlinedInput label={"Selected Users"} />
								}
								renderValue={(selected) =>
									selected
										.map((value) => {
											const user = users.find(
												(user) => user.id === value
											);
											return user ? user.email : value;
										})
										.join(", ")
								}
							>
								<MenuItem>
									<Checkbox
										checked={selectAll}
										indeterminate={
											data.emails.length > 0 &&
											data.emails.length < users.length
										}
										onChange={(e) => {
											if (e.target.checked) {
												// Select all users when "Select All" is checked
												setData({
													...data,
													emails: users.map(
														(user) => user.email
													),
												});
												setSelectAll(true);
											} else {
												// Deselect all users when "Select All" is unchecked
												setData({
													...data,
													emails: [],
												});
												setSelectAll(false);
											}
										}}
									/>
									<ListItemText
										primary={
											data.emails.length ===
												users.length && selectAll
												? "Deselect All"
												: "Select All"
										}
									/>
								</MenuItem>
								{users.map((user) => (
									<MenuItem
										key={user.email}
										value={user.email}
									>
										<Checkbox
											checked={
												data.emails.indexOf(
													user.email
												) > -1
											}
										/>
										<ListItemText primary={user.email} />
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					{mode === "category" && (
						<FormControl
							fullWidth
							className="input-field"
							variant="outlined"
							required
							disabled={loading}
						>
							<InputLabel
								htmlFor="outlined-adornment"
								className="input-label"
							>
								Category
							</InputLabel>

							<Select
								className="w-full"
								value={data.category_id}
								onChange={(e) => {
									setData({
										...data,
										category_id: e.target.value,
									});
								}}
								input={<OutlinedInput label={"Category"} />}
							>
								{categories.map((category, index) => (
									<MenuItem
										key={category.id}
										value={category.id}
									>
										<ListItemText
											primary={category.category_name}
										/>
									</MenuItem>
								))}
							</Select>
						</FormControl>
					)}

					<LoadingButton
						variant="contained"
						color="primary"
						type="submit"
						endIcon={<Send />}
						loading={loading}
					>
						Send
					</LoadingButton>
				</form>
			</Card>
		</Box>
	);
}
