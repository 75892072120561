import React from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Add, Category } from "@mui/icons-material";
import AddButton from "../../components/Buttons/AddButton";
import ExcelTable from "./ExcelTable";
import CategoryTable from "./CategoryTable";

export default function Storage() {
	const navigate = useNavigate();
	return (
		<div className="p-5 mx-auto flex items-center flex-col justify-center gap-4  max-w-3xl">
			<div className="md:hidden w-full">
				<Button
					fullWidth
					variant="contained"
					color="primary"
					startIcon={<Add sx={{ fontSize: "2rem" }} />}
					size="large"
					className="text-lg"
					onClick={() => navigate("/storage/add")}
				>
					Add Excel
				</Button>
			</div>
			<ExcelTable />
			<CategoryTable />
			<div className="hidden md:block">
				<AddButton onClick={() => navigate("/storage/add")} />
			</div>
		</div>
	);
}
