import { Box } from "@mui/material";
import config from "../../config";

export default function Analytics() {
	return (
		<Box
			className="mx-auto flex items-center flex-col gap-10 justify-center w-full"
			sx={{
				minHeight: `calc(100vh - ${config.topbarHeight}px)`,
			}}
		>
			<iframe
				src="https://dolphinmeta.priyo.com/public/question/5367300c-24e3-47a4-86e4-c9d6cd8a69df#theme=transparent&bordered=true&titled=true"
				frameborder="0"
				style={{
					height: `calc(100vh - ${config.topbarHeight}px)`,
					width: "100%",
				}}
				allowtransparency
				title="Analytics Question"
			></iframe>
		</Box>
	);
}
