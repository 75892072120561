import Api from "./base";

class EmailApi extends Api {
	sendCampaignEmailFromCsv = async (data) => {
		return await this.post("/send-campaign-email-from-csv/", data);
	};
	sendCampaignEmailByCategory = async (data) => {
		return await this.post("/send-campaign-email-by-category/", data);
	};
	sendCampaignEmail = async (data) => {
		return await this.post("/send-campaign-email/", data);
	};
}

const emailApi = new EmailApi();
export default emailApi;
