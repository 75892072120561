import { Add, Check, Clear, Edit, Save } from "@mui/icons-material";
import {
	Box,
	Button,
	Divider,
	IconButton,
	Paper,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import categoryApi from "../../api/categories";
import { showError } from "../../App";

function CategoryField({ index, category, categories, setCategories }) {
	const [isEditing, setIsEditing] = useState(false);
	const [updatedCategory, setUpdatedCategory] = useState(category);

	const handleDelete = async () => {
		const response = await categoryApi.deleteCategory(category.id);
		if (response.success) {
			const newCategories = [...categories];
			newCategories.splice(index, 1);
			setCategories(newCategories);
		}
	};

	const handleSave = async () => {
		const response = await categoryApi.updateCategory(
			categories[index].id,
			{
				category_name: updatedCategory.category_name,
			}
		);
		if (response.success) {
			setCategories((prev) => {
				const newCategories = [...prev];
				newCategories[index] = updatedCategory;
				return newCategories;
			});
			setIsEditing(false);
		} else {
			showError("Failed to update category");
		}
	};
	return (
		<div className="flex items-center justify-between w-full">
			<TextField
				fullWidth
				size="small"
				autoComplete="off"
				value={updatedCategory.category_name}
				onChange={(e) => {
					setUpdatedCategory((prev) => ({
						...prev,
						category_name: e.target.value,
					}));
				}}
				required
				disabled={!isEditing}
			/>
			{/* <IconButton
				onClick={() => {
					const newCategories = [...categories];
					newCategories.splice(index, 1);
					setCategories(newCategories);
				}}
			>
				<Clear />
			</IconButton> */}
			{isEditing ? (
				<IconButton onClick={handleSave}>
					<Check />
				</IconButton>
			) : (
				<IconButton
					onClick={() => {
						setIsEditing(true);
					}}
				>
					<Edit />
				</IconButton>
			)}
			<IconButton onClick={handleDelete}>
				<Clear />
			</IconButton>
		</div>
	);
}
export default function CategoryTable() {
	const [categories, setCategories] = useState([]);

	const fetchCategories = async () => {
		const response = await categoryApi.getAllCategories();
		if (response.success) {
			setCategories(response.data);
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const handleCreate = async () => {
		const response = await categoryApi.createCategory({
			category_name: "New Category",
		});
		if (response.success) {
			setCategories([...categories, response.data]);
		} else {
			showError("Failed to create category");
		}
	};
	return (
		<Paper className="w-full p-5 flex flex-col gap-2 items-start">
			<Typography
				variant="h6"
				fullWidth
				align="center"
				className="w-full"
			>
				Profile Categories
			</Typography>
			<Divider className="w-full" />
			{categories.map((category, index) => (
				<CategoryField
					{...{
						index,
						category,
						categories,
						setCategories,
					}}
				/>
			))}
			<Button startIcon={<Add />} onClick={handleCreate}>
				Add Category
			</Button>
			{/* <Button
				variant="contained"
				color="primary"
				size="large"
				fullWidth
				startIcon={<Save />}
			>
				Save
			</Button> */}
		</Paper>
	);
}
