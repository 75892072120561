import { Box, Button } from "@mui/material";
import FormsTable from "./FormsTable";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddButton from "../../components/Buttons/AddButton";
import quizApi from "../../api/quiz";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import profileApi from "../../api/profile";
import { showError } from "../../App";
import FormsGrid from "./FormsGrid";

export default function PriyoPlay() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const createForm = async () => {
		setLoading(true);
		const res = await quizApi.createQuiz({
			title: "Untitled form",
			description: "Form description",
			start_time: null,
			end_time: null,
			is_published: false,
			thumbnail: null,
			// message: "",
			questions: [
				{
					title: "Untitled Question",
					answer: {
						type: "multiple",
						options: ["Option 1"],
						correct: null,
					},
					points: 0,
				},
			],
			required_fields: await fetchSchema(),
		});

		if (res.success) {
			navigate(`/priyo-play/edit#${res.data.id}`);
		}
		setLoading(false);
	};

	const fetchSchema = async () => {
		const res = await profileApi.getProfileSchema();
		if (res.success) {
			const tmp_schema = res.data;
			const initialNews = {};
			console.log("Old Schema:", tmp_schema);
			Object.keys(tmp_schema).forEach((key) => {
				if (tmp_schema[key].editable) {
					initialNews[key] = null;
				}
				tmp_schema[key]["required"] = false;
			});
			
			return tmp_schema;
		} else {
			showError("Can't fetch schema");
		}
		return null;
	};

	return (
		<Box className="p-5 mx-auto flex items-center flex-col gap-10 w-full">
			<div className="md:hidden mb-4 w-full">
				<LoadingButton
					fullWidth
					variant="contained"
					color="primary"
					startIcon={<Add sx={{ fontSize: "2rem" }} />}
					size="large"
					className="text-lg"
					onClick={createForm}
					loading={loading}
				>
					Add Form
				</LoadingButton>
			</div>
			{/* <FormsTable /> */}
			<FormsGrid />
			<div className="hidden md:block">
				<div className="fixed bottom-5 z-10 right-5 items-center justify-center ">
					<LoadingButton
						onClick={createForm}
						variant="contained"
						color="primary"
						className="w-16 h-16 rounded-full justify-center inline-flex items-center p-4"
						loading={loading}
					>
						<Add sx={{ fontSize: "4rem" }} />
					</LoadingButton>
				</div>
			</div>
		</Box>
	);
}
