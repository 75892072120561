import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faAd,
	faArrowRightFromBracket,
	faBell,
	faBookAtlas,
	faBoxTissue,
	faBullhorn,
	faChartLine,
	faClipboardQuestion,
	faEarthAmericas,
	faFileCsv,
	faFishFins,
	faGears,
	faGlobe,
	faHardDrive,
	faHouse,
	faMailBulk,
	faMicrophoneLines,
	faNewspaper,
	faPen,
	faPlay,
	faRobot,
	faStoreAlt,
	faTable,
	faThumbTack,
	faUser,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
	AppBar,
	Box,
	Button,
	CssBaseline,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Toolbar,
	Typography,
} from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./index.css";
import ToggleMode from "../Buttons/ToggleButton";
import config from "../../config.json";
import Breadcrumb from "../Breadcrumb";
import authService from "../../services/auth";
import { getTokenFromLocalStorage } from "../../api/base";
import packageInfo from "../../../package.json";
import { text } from "@fortawesome/fontawesome-svg-core";
import { faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import { GiDolphin } from "react-icons/gi";

const drawerWidth = config.drawerWidth;
function NavButton({ icon, text, to }) {
	const location = useLocation();
	return (
		<ListItem key={text} disablePadding>
			<ListItemButton
				className={`text-[1.25rem] px-4 ${
					(to !== "/" && location.pathname.startsWith(to)) ||
					(to === "/" && location.pathname === "/")
						? "!bg-zinc-300 dark:!bg-zinc-700"
						: ""
				}`}
				component={Link}
				to={to}
			>
				<ListItemIcon sx={{ minWidth: "0px", width: "15%" }}>
					<FontAwesomeIcon icon={icon} />
				</ListItemIcon>
				<ListItemText
					primary={text}
					primaryTypographyProps={{
						sx: { fontSize: "1.25rem" },
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
}

function SubNavButton({ icon, text, to }) {
	const location = useLocation();
	return (
		<ListItem key={text} disablePadding>
			<ListItemButton
				className={`text-[1rem] px-4 ${
					(to !== "/" && location.pathname.startsWith(to)) ||
					(to === "/" && location.pathname === "/")
						? "!bg-zinc-300 dark:!bg-zinc-700"
						: ""
				}`}
				component={Link}
				to={to}
			>
				<ListItemIcon sx={{ minWidth: "0px", width: "15%" }}>
					<FontAwesomeIcon icon={icon} />
				</ListItemIcon>
				<ListItemText
					primary={text}
					primaryTypographyProps={{
						sx: { fontSize: "1rem" },
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
}

function LogoutButton() {
	const navigate = useNavigate();
	return (
		<ListItem key="Logout" disablePadding>
			<ListItemButton
				className={`text-[1.25rem] px-4`}
				// component={Link}
				// to="/login"
				onClick={async () => {
					await authService.logout();
					navigate(config.logoutRedirect);
				}}
			>
				<ListItemIcon sx={{ minWidth: "0px", width: "15%" }}>
					<FontAwesomeIcon icon={faArrowRightFromBracket} />
				</ListItemIcon>
				<ListItemText
					primary="Logout"
					primaryTypographyProps={{
						sx: { fontSize: "1.25rem" },
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
}

export default function LeftSidebar(props) {
	const { window } = props;
	const navigate = useNavigate();
	const [mobileOpen, setMobileOpen] = useState(false);
	const [isClosing, setIsClosing] = useState(false);
	const location = useLocation();

	const handleDrawerClose = () => {
		setIsClosing(true);
		setMobileOpen(false);
	};

	const handleDrawerTransitionEnd = () => {
		setIsClosing(false);
	};

	const handleDrawerToggle = () => {
		if (!isClosing) {
			setMobileOpen(!mobileOpen);
		}
	};

	useEffect(() => {
		if (mobileOpen) {
			setMobileOpen(false);
		}
	}, [location]);

	const drawer = (
		<div>
			<Toolbar className="!px-4">
				<div className="text-3xl font-bold font-logo text-[#444] dark:text-white flex flex-row justify-center w-full gap-1">
					{/* <FontAwesomeIcon
						icon={faFishFins}
						style={{
							transform: "rotate(-30deg)",
						}}
					/> */}
					<GiDolphin
						className="text-[#444] dark:text-white"
						style={{
							fontSize: "2.5rem",
						}}
					/>
					{packageInfo.name}
				</div>
			</Toolbar>
			<Divider />
			<List>
				{[
					{
						text: "Home",
						icon: faHouse,
						to: "/",
					},
					{
						text: "Profiles",
						icon: faUsers,
						to: "/profiles",
						submenu: [
							{
								text: "Import CSV",
								icon: faFileCsv,
								to: "/storage",
							},
						],
					},
					{
						text: "News",
						icon: faNewspaper,
						to: "/news",
					},
					{
						text: "Play",
						icon: faCirclePlay,
						to: "/priyo-play",
					},
					{
						text: "Campaign",
						icon: faBullhorn,
						to: "/campaign",
					},
					{
						text: "Email",
						icon: faMailBulk,
						to: "/email",
					},
					{
						text: "Notification",
						icon: faBell,
						to: "/notification",
					},
					{
						text: "Analytics",
						icon: faChartLine,
						to: "/analytics",
					},
				].map(({ text, icon, to, submenu }, index) => (
					<>
						<NavButton
							icon={icon}
							text={text}
							to={to}
							key={index}
						/>
						{submenu && (
							<List
								component="div"
								disablePadding
								className="!pl-4"
							>
								{submenu.map(({ text, icon, to }, index) => (
									<SubNavButton
										icon={icon}
										text={text}
										to={to}
										key={index}
									/>
								))}
							</List>
						)}
					</>
				))}
			</List>
			<Divider />
			<List>
				<LogoutButton />
			</List>
			<Box
				className="absolute left-0 bottom-0 p-2 text-center flex flex-col"
				sx={{ width: `${drawerWidth - 16}px` }}
			>
				<Typography variant="caption">
					{jwtDecode(getTokenFromLocalStorage()).email}
				</Typography>
				<Typography variant="caption" color={"textSecondary"}>
					{"v" + packageInfo.version}
				</Typography>
			</Box>
		</div>
	);
	const container =
		window !== undefined ? () => window().document.body : undefined;
	return (
		<div
			className={`relative top-0 bottom-0 left-0 h-screen overflow-hidden`}
			style={{ width: `${drawerWidth}px` }}
		>
			<CssBaseline />
			<AppBar
				position="fixed"
				sx={{
					width: { md: `calc(100% - ${drawerWidth}px)` },
					ml: { md: `${drawerWidth}px` },
					display: { xs: "flex", md: "none" },

					backgroundColor: (theme) =>
						theme.palette.background.default, // Use theme's white color
				}}
			>
				<Toolbar
					className="flex flex-row justify-between items-center"
					sx={{ paddingRight: { xs: "0.25rem", md: "0.75rem" } }}
				>
					<div className="flex flex-row items-center">
						<IconButton
							// color="inherit"
							aria-label="open drawer"
							edge="start"
							onClick={handleDrawerToggle}
							sx={{
								mr: 2,
							}}
						>
							<MenuIcon />
						</IconButton>
						<Breadcrumb />
					</div>

					<ToggleMode />
				</Toolbar>
			</AppBar>
			<Box
				component="nav"
				sx={{
					width: { md: `${drawerWidth}px` },
					flexShrink: { md: 0 },
				}}
				aria-label="mailbox folders"
			>
				<Drawer
					container={container}
					variant="temporary"
					open={mobileOpen}
					onTransitionEnd={handleDrawerTransitionEnd}
					onClose={handleDrawerClose}
					ModalProps={{
						keepMounted: true,
					}}
					sx={{
						display: { xs: "block", md: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: `${drawerWidth}px`,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					variant="permanent"
					sx={{
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: `${drawerWidth}px`,
						},
						display: { xs: "none", md: "block" },
					}}
					open
					// className="hidden md:block"
				>
					{drawer}
				</Drawer>
			</Box>
		</div>
	);
}
