import Api from "./base";

class CategoryApi extends Api {
	getAllCategories = async () => {
		return await this.get("/categories/");
	};

	createCategory = async (data) => {
		return await this.post("/categories/", data);
	};

	updateCategory = async (id, data) => {
		return await this.put(`/categories/${id}/`, data);
	};

	deleteCategory = async (id) => {
		return await this.delete(`/categories/${id}/`);
	};
}

const categoryApi = new CategoryApi();
export default categoryApi;
